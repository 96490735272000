html {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

body {
    height: 100%;
}

*:focus {
    outline: none !important;
}

button:focus {
    outline: 0 !important;
}
label {
    font-weight: normal;
}
figure {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}
ol {
    display: block;
    list-style-type: decimal;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    -webkit-padding-start: 0;
}

h2 {
    margin: auto !important;
    -webkit-margin-before: 0 !important;
    -webkit-margin-after: 0 !important;
    -webkit-margin-start: 0 !important;
    -webkit-margin-end: 0 !important;
}

.wrapper {
    position: relative;
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    min-height: 100%;
    overflow: hidden;
}

.wrapper header {
    z-index: 1060;
    position: fixed;
    top: 0;
    width: 100%;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.footer {
    height: 60px;
    padding: 15px;
    border-top: 1px solid #cfdbe2;
    color: #9E9E9E !important;
    font-size: 16px !important;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

ul {
    display: block;
    list-style-type: disc;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 40px;
}

.sidebar-nav {
    top: 0;
    width: 250px;
    padding: 0;
    margin: 2px 0 0;
}

.sidebar-nav ul {
    list-style-type: none;
    -webkit-padding-start: 26px;
}

.sidebar-nav li {
    text-indent: 12px;
    line-height: 40px;
}

.sidebar-nav li a {
    display: block;
    text-decoration: none;
    color: #999999;
    border-radius: 0 !important;
    cursor: pointer;
}

.sidebar-nav li a:hover {
    text-decoration: none;
    background-color: #333;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.sidebar-nav .sidebar-active {
    color: #0288D1;
    background: transparent !important;
}

.sidebar-nav .sidebar-active a {
    color: #64B5F6;
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
    text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px;
}

.sidebar-nav > .sidebar-brand a {
    color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
    color: #fff;
    background: none;
}

.sidebar-submenu {
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    -webkit-padding-start: 15px;

}

.sidebar-submenu li {
    line-height: 30px;
}

.content-header {
    z-index: 2;
    color: rgba(0,0,0,0.6);
    height: 60px;
}

.content-header .title {
    font-size: 24px;
    z-index: 4;
}

.content-header .title md-icon {
    margin-right: 12px;
    color: rgba(0,0,0,0.6);
}

.content-header .breadcrumb {
    padding: 0 !important;
    margin-bottom: 0;
    list-style: none;
    border-radius: 0;
    line-height: inherit;
    font-size: 2.5rem;
    background-color: rgb(33, 150, 243);
}

.content-header .breadcrumb a {
    color: #444;
}

.content-header .breadcrumb a:hover {
    color: #FFF !important;
    text-decoration: none !important;
}

.content-header .breadcrumb > .active {
    color: #FFF;
}

.table > thead > tr > th {
    padding: 14px 8px;
    color: #9E9E9E;
}

.error {
    color: #a94442;
}

.sidebar-icon {
    text-indent: 5px;
    margin-right: 15px;
    font-size: 18px;
}

.nav-icon-colapse {
    font-size: 1.8rem;
}

.table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 1px solid #ddd;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.38);
}

.table > tbody > tr > td {
    vertical-align: bottom;
    border-bottom: 1px solid #ddd;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
}

.menu-right {
    height: 6rem;
}

.menu-right .button-menu {
    height: 6rem;
}

md-menu-item .md-focused {
    color: rgba(0, 0, 0, 0.87);
}

md-menu-item a:hover, a:active {
    color: rgba(0, 0, 0, 0.87);
}


body {
    background: #fafafa;
    font-family: 'Poppins', sans-serif;
    color: #333;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 10px;
    color: #ccc
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
    padding: 5px 15px;
}

.logo .logo-img {
    height: 45px;
    display: inline-block;
}

/* ==========================================================================
Generic styles
========================================================================== */
.error {
    color: white;
    background-color: red;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break: break-all;
}

.voffset {
    margin-top: 2px;
}

.voffset1 {
    margin-top: 5px;
}

.voffset2 {
    margin-top: 10px;
}

.voffset3 {
    margin-top: 15px;
}

.voffset4 {
    margin-top: 30px;
}

.voffset5 {
    margin-top: 40px;
}

.voffset6 {
    margin-top: 60px;
}

.voffset7 {
    margin-top: 80px;
}

.voffset8 {
    margin-top: 100px;
}

.voffset9 {
    margin-top: 150px;
}

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
    cursor: pointer;
}

.hand {
    cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
    display: inline;
    list-style: none;
    margin: 0;
    margin-left: 15px;
    padding: 0;
    vertical-align: 2px;
}

.point:last {
    margin: 0 !important;
}

.point {
    background: #DDD;
    border-radius: 2px;
    display: inline-block;
    height: 5px;
    margin-right: 1px;
    width: 20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert {
    text-overflow: ellipsis;
}

.alert pre {
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}

.no-padding-top {
    padding-top: 0 !important;
}

.no-padding-bottom {
    padding-bottom: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
    width: 1% !important;
}

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.jh-table > tbody > tr > td {
    /* Align text in td vertically (top aligned by Bootstrap) */
    vertical-align: middle;
}

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .dl-horizontal.jh-entity-details > dt {
        margin-bottom: 15px;
    }

    .dl-horizontal.jh-entity-details > dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
    outline: none;
}

.md-sidemenu .md-button {
    color: #dadad5;
    background-color: rgb(45, 50, 62);
}

.sub-menu {
    width: 93% !important;
}

.md-sidemenu md-icon {
    color: #dadad5 !important;
}
.md-sidemenu button {
    text-transform: uppercase !important;
}
.md-sidemenu button md-icon:first-child {
    margin-right: 16px;
}
.md-sidemenu-wrapper.md-sidemenu-wrapper-icons .md-button {
    padding-left: 56px;
}
.btn-icon {
    margin-top: -2px;
}

.sidemenu-divider {
    min-height: 30px;
}

.gfile-third-label {
    margin-bottom: 20px;
}

.gfile-third {
    color: rgba(0, 0, 0, 0.38);
    -webkit-box-ordinal-group: 3;
    order: 2;
    display: block;
    margin-top: 0;
    background: none;
    padding-top: 2px;
    padding-bottom: 1px;
    padding-left: 2px;
    padding-right: 2px;
    border-width: 0 0 1px 0;
    line-height: 26px;
    height: 30px;
    border-radius: 0;
    border-style: solid;
    width: 100%;
    box-sizing: border-box;
    float: left;
    border-color: rgba(0, 0, 0, 0.12);
    border-bottom-color: transparent;
}
@media (max-width: 959px) and (min-width: 0) and (orientation: landscape) {
    .md-toolbar-tools {
        height: 64px;
        max-height: 64px;
    }
}

@media (max-width: 959px) and (min-width: 0) and (orientation: portrait) {
    .md-toolbar-tools {
        height: 64px;
        max-height: 64px;
    }
}

h3 {
    margin: -20px -20px 20px;
    -webkit-margin-before: 0 !important;
    -webkit-margin-after: 0 !important;
    -webkit-margin-start: 0 !important;
    -webkit-margin-end: 0 !important;
    font-size: 15px;
    line-height: 1.1;
    color: #4c4e54;
}

.form-login h3 {
    font-size: 24px;
    margin: -20px 20px 20px 20px !important;
    line-height: 2;
    color: #4c4e54;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    padding: 24px;
    vertical-align: middle !important;
}

.table th {
    cursor: pointer;
}

.table  tr {
    cursor: pointer;
}

.text-align-right {
    text-align: right;
}

.button-form {
    margin-top: 25px;
    text-align: right;
}

.label-near-button-form {
    margin-top: 25px;
    margin-left: 5px;
    margin-right: -5px;
}

.fieldset-period {
    padding: 5px;
    border: 1px dashed #cfdbe2 !important;
    border-radius: 4px;
}

.legend-period {
    width: auto !important;
    border-bottom: none !important;
    margin-bottom: 5px !important;
    font-size: 1.6rem !important;
}

.warning {
    color: #FFEB3B !important;
}

.pagination-control {
    font-size: 1.2rem;
    color: #999999 !important;
    font-weight: 500 !important;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #999999 !important;
    border-color: #999999 !important;
    color: #fff;
}

.pagination > li > a, .pagination > li > span {
    color: #999999;
}

.finder {
    margin-top: 25px;
}

.nav-tabs > li > a {
    font-size: 1.25rem !important;
    color: #4c4e54;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    color: #9E9E9E;
}

.nav-tabs > li {
    float: left;
    margin-bottom: -2px;
}

.uib-accordion .panel-default {
    border-top-width: 1px !important;
    border-color: #cfdbe2 !important;
}

.uib-accordion .panel-default .panel-heading {
    background-color: #fafbfc;
}

.dashboard-col-right {
    padding-left: 0 !important;
}

.radio-left {
    display: inline-block !important;
    cursor: pointer !important;
    position: relative !important;
    margin-right: 5px !important;
}

.radio-left-label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    margin-right: 15px;
    font-size: inherit;
    font-weight: normal;
}

.radio-right {
    display: inline-block !important;
    cursor: pointer !important;
    position: relative !important;
    margin-right: 5px !important;
}

.radio-right-label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    margin-right: 15px;
    font-size: inherit;
    font-weight: normal;
}

.textarea {
    height: auto !important;
    margin-top: 5px;
    margin-bottom: 5px;
    resize: none;
}

.modal-dialog {
    margin: 90px auto !important;
}

.login a {
    color: #333;
    font-weight: bold;
    text-decoration: none;
    font-size: 1.2rem;
    margin-bottom: 17px;
}

.login h2 {
    font-size: 25px;
}

.mt0 {
    margin-top: 0 !important;
}

.c-checkbox, .c-radio {
    margin-right: 4px;
}

.c-checkbox input, .c-radio input {
    opacity: 0;
    position: absolute;
    margin-left: 0 !important;
}

.text-align-center {
    text-align: center;
}

.easy button span {
    background-color: #B2FF59;
    border-radius: 35px;
    color: black;
}

.medium button span {
    background-color: #FFFF8D;
    border-radius: 35px;
    color: black;
}

.hard button span {
    background-color: #FFAB91;
    border-radius: 35px;
    color: black;
}

/*.panel-heading h2 {
margin: auto !important;
} */

.d3-tip {
    line-height: 1;
    font-weight: normal;
    text-align: center;
    padding: 1.0rem;
    background: #BDBDBD;
    font-size: 1.2rem;
    color: #FFF;
    border-radius: 2px;
    pointer-events: none;
}

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
    box-sizing: border-box;
    display: inline;
    font-size: 1rem;
    width: 100%;
    line-height: 1;
    color: #BDBDBD;
    position: absolute;
    pointer-events: none;
}

/* Northward tooltips */
.d3-tip.n:after {
    content: "\25BC";
    margin: -1px 0 0 0;
    top: 100%;
    left: 0;
    text-align: center;
}

/* Eastward tooltips */
.d3-tip.e:after {
    content: "\25C0";
    margin: -4px 0 0 0;
    top: 50%;
    left: -8px;
}

/* Southward tooltips */
.d3-tip.s:after {
    content: "\25B2";
    margin: 0 0 1px 0;
    top: -8px;
    left: 0;
    text-align: center;
}

/* Westward tooltips */
.d3-tip.w:after {
    content: "\25B6";
    margin: -4px 0 0 -1px;
    top: 50%;
    left: 100%;
}

circle:hover {
    cursor: pointer;
}

.search-form-message {
    position: absolute;
    top: 45px;
    right: 15px;
    z-index: 1000;
    -webkit-box-shadow: 3px 3px 3px 0 rgba(217, 217, 217, 1);
    -moz-box-shadow: 3px 3px 3px 0 rgba(217, 217, 217, 1);
    box-shadow: 3px 3px 3px 0 rgba(217, 217, 217, 1);
}

@media (min-width: 992px) and (max-width: 1199px) {
    .search-form-message {
        top: 45px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .search-form-message {
        top: 45px;
    }
}

@media (max-width: 767px) {
    .search-form-message {
        top: 165px;
    }
}

.dashboard-widget {
    font-size: 1.2rem;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .dashboard-widget {
        font-size: 0.9rem;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .dashboard-widget {
        font-size: 0.8rem;
    }
}

@media (max-width: 767px) {
    .dashboard-widget {
        font-size: 0.7rem;
    }
}

.dashboard-widget .table th {
    text-align: right !important;
}

.dashboard-widget .table .data {
    text-align: right;
}

.dashboard-widget .table .no-data {
    white-space: nowrap;
    text-align: left;
}

.download-img {
    height: 1.9rem;
    width: auto;
    margin-right: 0.5rem;
}

.btn-link {
    color: #9E9E9E !important;
    text-decoration: none !important;
}

.btn-link:hover {
    color: #616161 !important;
    text-decoration: none !important;
}

.btn-link:hover {
    text-decoration: none !important;
}

.btn-link:focus, .btn-link:hover {
    color: #616161;
    text-decoration: underline;
    background-color: transparent;
}

.btn-link, .btn-link:active, .btn-link:focus, .btn-link:hover {
    border-color: transparent;
    text-decoration: none !important;
}

.axis path,
.axis line {
    fill: none;
    stroke: black;
    shape-rendering: crispEdge;
}

.bar rect {
    stroke: #fff;
    fill: none;
    shape-rendering: crispEdges;
}

.bar rect.background {
    fill: #f5f7fa;
}

.bar rect.data {
    fill: #90CAF9;
}

.bar text {
    fill: #616161;
}

.content-header-right {
    margin: 3px;
    text-align: right;
}

@media (min-width: 768px) and (max-width: 991px) {
    .content-header-right {
        text-align: left;
    }
}

@media (max-width: 767px) {
    .content-header-right {
        text-align: left;
    }
}

.content-header-right .input-group input {
    margin-top: 5px;
}

.angular-ui-tree-handle {
    padding: 5px;
    margin-bottom: 5px;
    min-height: 30px;
    line-height: 30px;
    border-radius: 4px;
    border: 1px #ccc dotted;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.vertical-center {
    min-height: 100%; /* Fallback for browsers do NOT support vh unit */
    min-height: 60vh; /* These two lines are counted as one :-)       */
    display: flex;
    align-items: center;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
    color: #fff;
    background-color: #2196F3;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
    color: #fff;
    text-decoration: none;
    background-color: #2196F3;
    outline: 0;
}

fieldset {
    border: 1px dotted #ccc;
    border-radius: 4px !important;
}

legend {
    display: block;
    width: inherit;
    padding: 5px;
    margin: 10px;
    font-size: 1.8rem;
    line-height: inherit;
    border: 0;
    border-bottom: none;
}

.sidenav-filter {
    background-color: transparent !important;
}

.sidenav-filter .md-subheader {
    color: #000 !important;
    background-color: rgb(250, 250, 250);
}

.sidenav-filter .new-button {
    padding: 85px 0 25px 0;
}

.sidenav-form {
    padding: 20px;
}

@media (max-width: 1279px) and (min-width: 0) {
    .sidenav-filter {
        max-width: 30% !important;
        background-color: #fafafa !important;
    }
}
.form-box {
    margin-left: 16px;
    margin-right: 16px;
}
.form-box .md-button {
    margin-right: 0;
    padding-left: 16px;
    padding-right: 16px;
}
.new-item {
    margin-top: 5px;
    margin-bottom: 10px;
    padding-right: 20px;
}

.result-box {
    margin-top: 4px;
    margin-bottom: 8px;
    margin-left: 16px;
    margin-right: 16px;
    z-index: 2;
    background-color: #FFF;
}
.result-box table {
    margin-bottom: 0;
}
.result-box .md-button {
    color: #999999;
}
.result-box .md-button md-icon {
    color: #999999 !important;
    margin-right: 8px;
}
.form-box md-input-container {
    margin-bottom: 0 !important;
}
.tasks {
    margin-left: 32px !important;
}

.navbar .icon-theme {
    color: #FFF;
}

md-tabs.md-default-theme .md-tab.md-active, md-tabs .md-tab.md-active, md-tabs.md-default-theme .md-tab.md-active md-icon, md-tabs .md-tab.md-active md-icon, md-tabs.md-default-theme .md-tab.md-focused, md-tabs .md-tab.md-focused, md-tabs.md-default-theme .md-tab.md-focused md-icon, md-tabs .md-tab.md-focused md-icon {
    color: #333;
}

md-tabs.md-default-theme md-ink-bar, md-tabs md-ink-bar {
    color: rgb(0, 0, 0);
    background: #00BCD4;
}

#tree-root {
    padding: 15px;
    width: 100%;
}

.table-content {
    z-index: 2;
}

.top-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    height: 173px;
    background-image: url(../images/sky-183869_1920.jpg);
    background-size: cover;
}

.content-detail {
    z-index: 3;
    background-color: #FFF;
}

.notes textarea, .notes input {
    width: 100%;
    padding: 12px;
    font-size: 15px;
    font-weight: 300;
}

.notes input {
    font-weight: 500;
}

:not(md-input-container) > button, :not(md-input-container) > input[type=email], :not(md-input-container) > input[type=tel], :not(md-input-container) > input[type=text], :not(md-input-container) > input[type=password], :not(md-input-container) > input[type=image], :not(md-input-container) > input[type=submit], :not(md-input-container) > input[type=button], :not(md-input-container) > input[type=search], :not(md-input-container) > textarea {
    border-radius: 0;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    padding: 0;
    margin: 0;
}

.notes .gray {
    background: #f5f5f5;
}

.notes .green {
    background: #b9f6ca;
}

.notes .yellow {
    background: #ffff8d;
}

.notes .blue {
    background: #84ffff;
}

.notes .darkBlue {
    background: #80d8ff;
}

.notes .deepBlue {
    background: #448aff;
}

.notes .purple {
    background: #b388ff;
}

.notes .lightPurple {
    background: #8c9eff;
}

.notes .red {
    background: #ff8a80;
}

.notes .pink {
    background: #ff80ab;
}

.notes .white {
    background: #fff;
}

.notes .done {
    background: #ccc;
    opacity: 0.40;
    cursor: not-allowed;
}

.notes new-note {
    display: block;
    max-width: 600px;
    margin: 32px auto;
    overflow: hidden;
    border-radius: 2px;
}

.notes new-task {
    display: block;
    max-width: 600px;
    margin: 32px auto;
    overflow: hidden;
    border-radius: 2px;
}

.notes edit-note {
    display: block;
    max-width: 600px;
    margin: 32px auto;
    overflow: hidden;
    border-radius: 2px;
}

.notes edit-task {
    display: block;
    max-width: 600px;
    margin: 32px auto;
    overflow: hidden;
    border-radius: 2px;
}

.notes md-grid-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.notes md-grid-tile {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.notes new-task .new-task-button {
    display: block;
    font-size: 15px;
    font-weight: 300;
    opacity: 0.50;
    color: rgba(0, 0, 0, .8);
    cursor: text;
}

.notes new-note .new-note-button {
    display: block;
    font-size: 15px;
    font-weight: 300;
    opacity: 0.50;
    color: rgba(0, 0, 0, .8);
    cursor: text;
}

.notes .md-datepicker-input {
    padding: 0 0 5px;
}

.notes md-grid-list md-grid-tile figure .text {
    font-size: 15px;
    margin: 10px;
}

.notes md-grid-list md-grid-tile figure .icon {
    color: #fff !important;
}

figcaption {
    display: block;
    width: 100%;
}

.alarm-icon {
    color: white;
    font-size: 23px !important;
}

.main-content {
    z-index: 4;
    background-color: #fafafa;
}

.login {
    height: 100%;
    background-color: #efefef;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.container-login {
    min-height: 100%;
}

.form-login {
    z-index: 1000;
    padding: 30px;
    /*    width: 700px; */
}

.form-login .header {
    margin-top: 15px;
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 30px;
}

.form-reset {
    padding: 30px;
    width: 700px;
}

.form-reset .header {
    margin: 30px;
}

.md-48 {
    font-size: 38px !important;
    margin-right: 10px;
}

.primary {
    height: 100%;
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    background-color: transparent !important;
    background-image: none !important;
    color: rgb(0, 0, 0) !important;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #000;

}

.md-sidenav-left {
    background-color: rgb(45, 50, 62);
    min-height: 100%;
    width: 280px;
}

::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}

::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
}

::-webkit-scrollbar-thumb:active {
    background: #000000;
}

::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
    background: #666666;
}

::-webkit-scrollbar-track:active {
    background: #333333;
}

::-webkit-scrollbar-corner {
    background: transparent;
}

.btn-filter {
    text-transform: none !important;
}

.steps {
    position: relative;
    height: auto;
}

.steps:before {
    display: block;
    position: absolute;
    z-index: 10;
    content: "";
    width: 2px;
    background: rgba(0, 0, 0, .08);
    top: 0;
    left: 50%;
    bottom: 0;
    margin-left: -1px;
}

.step-point {
    position: absolute;
    width: 56px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    border-radius: 50%;
    left: 50%;
    margin: 0 0 0 -35px;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
}

.step-point-left {
    margin-left: -74px;
}

.step-phase {
    padding-right: 68px;
    padding-top: 16px;
    text-align: right;
}


.step-phase .title {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
}

.step-phase-2 .title {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
}
.step-document {
    padding-left: 68px;
    padding-top: 16px;
}

.step-phase-1 .title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
}

.step-phase-1 .period {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.38);
}

.step-document .title {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: left;
}

.step-document .period {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.38);
    text-align: left;
}
.step-title {
    font-size: 18px !important;
}
.step-1-title {
    font-size: 17px !important;
}
.step-2-title {
    font-size: 16px !important;
}
.step-3-title {
    font-size: 15px !important;
}
.step-phase .period {
    color: #9E9E9E;
}
.history:before {
    display: block;
    position: absolute;
    z-index: 10;
    content: "";
    width: 1px;
    background: #9d9d9d;
    opacity: 0.30;
    top: 45px;
    left: 33%;
    bottom: 20px;
    margin-left: -1px;
}

.history-item {
    overflow: hidden;
    position: relative;
    z-index: 20;
    padding: 22px;
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.history-item .history-item-detail, #timeline .ms-timeline-item:nth-child(even).animate .timeline-card {
    -webkit-animation: slide-in-right .4s;
    animation: slide-in-right .4s;
}

.history-item .history-item-detail {
    -webkit-box-flex: 50%;
    -ms-flex: 50% 0 0;
    flex: 50% 0 0;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
}
.history-item .history-item-document {
    -webkit-box-flex: 50%;
    -ms-flex: 50% 0 0;
    flex: 50% 0 0;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 4;
    order: 4;
}

.history-item .history-card {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
}

.history-item .history-card-right {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.history-item .history-card-left {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    padding-right: 0;
}

.flex-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 33%;
    max-height: 100%;
    box-sizing: border-box;
}

.panel-task-toolbar {
    background-color: #fff !important;
    color: #000 !important;
    font-size: 15px;
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .08);
}

.panel-task-toolbar .select-time {
    padding-left: 32px;
}

.strike {
    text-decoration: line-through;
}

.todo-item {
    position: relative;
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, .08);
    text-transform: none;
    cursor: pointer;
}

.todo-item .title {
    font-size: 16px;
    font-weight: 500;
}

.panel-task-button, .panel-task-button:hover, .panel-task-button:active, .panel-task-button:focus {
    text-decoration: none
}

.taskdone {
    background: rgba(0, 0, 0, 0.18);
}

.no-results {
    padding: 16px;
}

.chart-title {
    padding: 10px 0;
}

.chart-value {
    font-size: 50px;
    color: #757575;
}

.chart-panel {
    border: 2px;
    border-radius: 5px;
    border-style: solid;
    border-color: #9E9E9E;
    padding: 10px 20px;
    margin-top: 20px;
}

.signatory-control-header {
    border-bottom: 1px solid rgba(0, 0, 0, .08);
    background-color: #FFFFFF;
}

.signatory-item {
    position: relative;
    padding: 16px !important;
    border-bottom: 1px solid rgba(0, 0, 0, .08);
    text-transform: none;
    cursor: pointer;
}

.signatory-item .title {
    font-weight: 700;
}

.no-items {
    color: #757575;
    padding: 16px;
}

.has-notes {
    font-weight: 800;
}
.login-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    filter: grayscale(90%) brightness(80%) contrast(2); 
    overflow: hidden;
    background-size: cover;
    background-position: 50%;
    background-image: url('../images/agreement.jpg')
}
.login-background:after {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    content: "";
    display: block;
    background: #777;
    opacity: .91;
}
.profile-container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 10px;
    background-color: #363c4a;
}
.profile {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}
.profile .avatar {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-size: cover;
    background-image: url('../images/profile.jpg');
}
.profile .description {
    color: #FFF;
    padding-left: 10px;
    align-items: center;
    justify-content: center;
}
.profile .description .name {
    font-size: 16px;
    text-align: left;
}
.profile .description .title {
    text-align: left;
}
.sidebar-header {
    padding-top: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.logo-container {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.logo-login {
    width: 50%;
    height: 45px;
    z-index:9000;
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: contain;
    background-image: url('../images/logo.png');
}
.logo-subtitle-login {
    margin-top: 10px;
    margin-bottom: 30px;
    width: 50%;
    height: 20px;
    z-index: 9000;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-image: url(../images/logo-subtitle.png);
}
.logo {
    width: 100%;
    height: 45px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../images/logo.png');
}
.logo-subtitle {
    margin-top: -10px;
    width: 90%;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../images/logo-subtitle.png');
}
.md-sidemenu-button md-icon:first-child {
    margin-right: 16px;
}
.info {
    margin-right: 8px;
}
.pagination {
    margin-right: 16px;
}
.subform {
  margin-top: 55px;
  margin-bottom: 55px;
}
.page-subtitle {
    display: flex;
    font-size: 21px;
    justify-content: flex-start;
    align-items: center;
    color: rgba(0,188,212) !important;
    margin-bottom: 15px;
}
.page-subtitle md-icon {
  color: rgba(0,188,212) !important;
  margin-right: 8px;
}
md-input-container md-icon {
    color: rgba(0,0,0,0.38);
}
md-switch.md-default-theme .md-bar, md-switch .md-bar {
    background-color: #E0E0E0 !important;
}
md-switch.active .md-thumb {
    background-color: #EF5350 !important;
}
md-switch.active.md-checked .md-thumb {
    background-color: #26A69A !important;
}
md-switch.md-checked .md-thumb {
    background-color: #26A69A !important;
}
.table-details .title{
    font-weight: 500;
}
.table-details .detail {
    font-size: 13px;
    color: rgba(0,0,0,0.6);
}
.column-center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.md-error-spacer {
    min-height: 24px;
}
.tree-node {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 2px;
}

.node-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.node-item .md-icon-button {
    margin: 0;
}
.node-item .document-icon {
    margin-left: 8px;
}
.node-item .title {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
    line-height: 18px;
}
.node-item .period {
    line-height: 16px;
    color: #9E9E9E;
    font-size: 13px;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.node-item .period span {
    margin-right: 2px;
}
.node-item .period div {
    margin-left: 5px;
}
.node-item .step-description-margin {
    margin-left: 8px;
}
.md-input-container {
    margin: 18px 0 0 0;
}

.taskexpired {
    color: #F44336 !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.pl-10 {
    padding-left: 10px !important;
}

.pr-10 {
    padding-right: 10px !important;
}

.pt-10 {
    padding-top: 10px !important;
}
.pb-10 {
    padding-bottom: 10px !important;
}

.pl-20 {
    margin-left: 20px !important;
}

.px-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
}

.py-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.justify-content-end {
    justify-content: end;
}

.bt-save {
    background-color: rgb(0,190,255) ! important;
}

.md-dialog-content .ng-binding{
    padding-top: 10px;
}

#custom-autocomplete md-autocomplete-wrap {
    align-items: baseline;
    
}

#custom-autocomplete md-autocomplete-wrap input {
    padding-left: 1.5rem;
}

.visibility-hidden {
    visibility: hidden;
}
